import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'video-player',
  inheritAttrs: false
})
export default class VideoPlayer extends Vue {
  @Prop({ type: String, required: true })
  videoUrl!: String;

  /**
   * The caption file URL for the video.
   */
  @Prop({ type: String, default: '' })
  captionUrl!: String;

  get crossOrigin(): string | undefined {
    return this.captionUrl
      ? 'anonymous'
      : undefined;
  }
}
